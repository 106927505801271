<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="Histórico" :cols="7">
          <v-card v-if="historico" dark>
            <v-card-title>Relatório por Rota</v-card-title>
            <v-card-title>
              <v-menu
                ref="menuData"
                v-model="menuData"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-btn color="#7366aa" small v-on="on">
                    Data:
                    {{ moment(String(data)).format('DD/MM/YYYY') }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="data"
                  locale="pt-pt"
                  no-title
                  @input="menuData = false">
                </v-date-picker>
              </v-menu>
            </v-card-title>
            <v-data-table dark hide-default-footer
              style="height: 580px"
              :headers="hstCircuitosHeader"
              :items="historico"
              :loading="loaders.report"
              loading-text="A carregar..."
              :search="search">
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-btn fab dark small
                  class="mx-2"
                  color="#7366aa"
                  @click="pesquisarCircuito(item)"
                  @dblclick="editarCircuito(item)">
                  <v-icon dark>mdi-magnify-plus</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.lcamiao`]="{ item }">
                <div @dblclick="editarCircuito(item)">
                  {{item.lcamiao}}
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col :cols="5" style="height: 650px">
          <v-card style="height: 650px; width: 100%" dark>
            <v-card-title>
              Mapa
              <v-spacer></v-spacer>
            </v-card-title>
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              v-on:update:zoom="updateZoom($event)"
              ref="mymap">
              <l-marker
                :lat-lng="getlatLng(41.38150949313275, -8.380767029624579)">
                <l-icon
                  :icon-size="dynamicSizeCamiao"
                  :icon-anchor="dynamicAnchorCamiao"
                  :icon-url="API_URL+'/img/resinorte.png'"/>
                <l-popup>
                  <div>Resinorte</div>
                </l-popup>
              </l-marker>

              <l-polyline
                v-if="cartrack"
                :lat-lngs="cartrack"
                color="#347a8d">
              </l-polyline>
              <l-geo-json
                v-if="geojson"
                :geojson="geojson"
                :options-style="myStyle">
              </l-geo-json>
              <l-tile-layer :url="url" :attribution="attribution" />
              <template v-if="report">
                <l-marker
                  id="marker.id"
                  v-for="marker in report"
                  :key="'m'+marker.id"
                  :visible="(marker.position!==null)"
                  :draggable="marker.draggable"
                  :lat-lng="marker.position">
                  <l-icon
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    :icon-url="API_URL+'/img/'+getColorNivelSensor(marker.nivel)+'.png'"/>
                  <l-popup>
                  <div>
                    Contentor: {{ marker.numero_contentor }}
                    <br />
                    <v-chip dark small
                      :color="getColorNivelSensorTabela(marker.nivel)">
                      {{ marker.nivel }}%
                    </v-chip>
                    <p>
                      Rua: {{ marker.rua }} <br />
                      Freguesia: {{ marker.freguesia }} <br />
                    </p>
                  </div>
                  </l-popup>
                </l-marker>
              </template>
            </l-map>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col key="reports" :cols="8" v-if="selecionado">
          <v-card v-if="report" dark>
            <v-card-title>
              <span class="mr-3">Histórico dos Reports</span>
              <v-btn fab dark small
                class="mx-3"
                color="rgb(244, 15, 2)"
                @click="exportar()">
                <v-icon title="Exportar PDF" dark>mdi-pdf-box</v-icon>
              </v-btn>
              <v-btn fab dark small
                class="mx-3"
                color="rgb(29, 111, 66)"
                @click="exportarXLS()">
                <v-icon title="Exportar XLS" dark>mdi-file-excel-box</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table dark hide-default-footer
              :headers="historicoHeader"
              :items="report"
              :loading="loaders.historicoReport"
              loading-text="A carregar..."
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc">
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.sensor`]="{ item }">
                <v-icon dark
                  v-if="item.sensor"
                  title="Sensor Ligado"
                  color="green">
                  mdi-wifi
                </v-icon>
                <v-icon v-if="!item.sensor" title="Sem Sensor" color="grey" dark>
                  mdi-wifi
                </v-icon>
              </template>
              <template v-slot:[`item.fotos`]="{ item }">
                <a
                  v-for="foto in item.fotos"
                  :key="'f'+foto.id"
                  :href="API_URL+foto.url"
                  target="_blank">
                  <img :src="API_URL+foto.url" height="70px" width="70px" />
                </a>
              </template>

              <template v-slot:[`item.report`]="{ item }">
                <v-icon large dark tile
                  title="Lixo recolhido"
                  :color="item.recolheuLixo ? 'green' : 'grey'">
                  mdi-check
                </v-icon>
                <v-icon dark tile
                  title="Lixo fora do contentor"
                  :color="item.lixofora ? '#ebbf41' : 'grey'">
                  mdi-basket-unfill
                </v-icon>
                <!--<v-icon
                  title="Tampa Partida"
                  dark
                  :color="item.tampaPartida ? '#347a8d' : 'grey'"
                  tile>mdi-delete-empty
                </v-icon>-->
                <v-icon
                  :color="item.temAvaria ? '#f24747' : 'grey'"
                  title="Tem Anomalia">
                  mdi-delete-forever
                </v-icon>
                <v-icon dark tile
                  title="Precisa de higienização"
                  :color="item.precisaLimpeza ? '#fa8748' : 'grey'">
                  mdi-broom
                </v-icon>
                <v-icon dark tile
                  title="Tem monstro"
                  :color="item.temMonstro ? '#6e6eba' : 'grey'">
                  mdi-seat
                </v-icon>
                <v-icon dark tile
                  title="Fez higienização"
                  :color="item.fezHigenizacao ? '#ff00ff' : 'grey'">
                  mdi-spray-bottle
                </v-icon>
                <v-icon dark tile
                  title="Recolheu Monstro"
                  :color="item.recolheuMonstro ? '#3366ff' : 'grey'">
                  mdi-arrow-collapse-up
                </v-icon>
                <v-icon dark tile
                  title="Fez Manutenção"
                  :color="item.fezManutencao ? '#ffffff' : 'grey'">
                  mdi-delete-circle-outline
                </v-icon>
                <br />
                <span v-if="item.obsLixo" class="mx-2">
                  <b>Obs:</b> {{ item.obsLixo }}
                </span>
                <span v-if="item.obsAvaria" class="mx-2">
                  <b>Anomalia:</b> {{ item.obsAvaria }}
                </span>
              </template>

              <template v-slot:[`item.position`]="{ item }">
                <v-btn fab dark small
                  class="mx-2"
                  color="#7366aa"
                  @click="centerZoom(item.position)">
                  <v-icon dark>
                    mdi-magnify-plus
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.nivel`]="{ item }">
                <v-chip dark style="width: 100%; text-align: center;"
                  :color="getColorNivelSensorTabela(item.nivel)"
                  v-if="item.nivel">
                  {{ item.nivel }}%
                </v-chip>
              </template>
              <template v-slot:[`item.data`]="{ item }">
                {{ dateToHHMM(item.data) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col key="registro" :cols="4" v-if="selecionado.kms">
          <v-row dense>
            <v-col :cols="6">
              <v-card dark
                :loading="this.loaders.historicoReport">
                <v-card-subtitle>
                  Total (T):
                  <v-spacer></v-spacer>
                </v-card-subtitle>
                <center>
                  <h1>{{ (selecionado.descarga / 1000).toLocaleString("pt-PT") }}</h1>
                </center>
              </v-card>
            </v-col>
            <v-col :cols="6">
              <v-card dark
                :loading="this.loaders.historicoReport">
                <v-card-subtitle>
                  Distância (Km):
                  <v-spacer></v-spacer>
                </v-card-subtitle>
                <center>
                  <h1>{{ selecionado.kmEnd ? (selecionado.kmEnd - selecionado.kmStart).toLocaleString("pt-PT") : "0" }}</h1>
                </center>
              </v-card>
            </v-col>
            <v-col :cols="6">
              <v-card dark
                :loading="this.loaders.historicoReport">
                <v-card-subtitle>
                  Tempo (HH:mm):
                  <v-spacer></v-spacer>
                </v-card-subtitle>
                <center>
                  <h1>{{ msToHHMM(selecionado.duration) }}</h1>
                </center>
              </v-card>
            </v-col>
            <v-col :cols="6">
              <v-card dark
                :loading="this.loaders.historicoReport">
                <v-card-subtitle>
                  Recolhidos:
                  <v-spacer></v-spacer>
                </v-card-subtitle>
                <center>
                  <h1>{{ selecionado.reports.filter(val => val.recolheuLixo == true).length }} / {{ selecionado.reports.length}}</h1>
                </center>
              </v-card>
            </v-col>
            <v-col :cols="6">
              <v-card dark
                :loading="this.loaders.historicoReport">
                <v-card-subtitle>
                  Enchimento médio:
                  <v-spacer></v-spacer>
                </v-card-subtitle>
                <center>
                  <h1>{{ enchimentoMedio }}%</h1>
                </center>
              </v-card>
            </v-col>
            <v-col :cols="6">
              <v-card dark
                :loading="this.loaders.historicoReport">
                <v-card-subtitle>
                  Descargas:
                  <v-spacer></v-spacer>
                </v-card-subtitle>
                <center>
                  <h1>{{ selecionado.kms.filter(val => val.tipo == "descarga").length }}</h1>
                </center>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12">
              <v-card dark>
                <v-card-title>Histórico de Registros</v-card-title>
                <v-data-table dark hide-default-footer
                  :headers="registrosHeader"
                  :items="selecionado.kms"
                  :loading="loaders.historicoReport"
                  loading-text="A carregar...">
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                  </template>
                  <template v-slot:[`item.data_registro`]="{ item }">
                    {{ dateToHHMM(item.data_registro) }}
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <br><br>

    <v-dialog v-model="editKMs" v-if="editKMs" style="z-index: 999 !important" width="500px">
      <v-card :disabled = "loaders.savingKMs">
        <v-card-title class="headline grey lighten-2" primary-title>
          Quilometros
        </v-card-title>
        <v-card-text>
          <v-form ref="formHST" lazy-validation>
            <v-row v-for="km in editHST.kms" :key="'k'+km.id">
              <v-col>
                <v-text-field disabled
                  v-model="km.id"
                  label="ID">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field disabled
                  v-model="km.tipo"
                  label="Tipo">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="km.km"
                  label="Quilometros">
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn @click="saveHST(km.id, km.km)"
                  color="success">
                    Salvar
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-text-field
              v-model="selecionado.nome"
              label="Nome referência"
              :rules="[rules.required]">
            </v-text-field>
            <v-text-field
              v-model="selecionado.matricula"
              label="Matrícula"
              :rules="[rules.required]">
            </v-text-field>
            <v-text-field
              v-model="selecionado.modelo"
              label="Modelo">
            </v-text-field>
            <v-text-field
              v-model="selecionado.ano"
              label="Ano">
            </v-text-field>
            <v-text-field
              v-model="selecionado.fabricante"
              label="Fabricante">
            </v-text-field>
            <v-text-field
              v-model="selecionado.combustivel"
              label="Combustível">
            </v-text-field> -->
            <v-btn color="error" class="mr-4" @click="editKMs = false">
              Cancelar
            </v-btn>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import { icon, latLng } from "leaflet";
import mixin from '../plugins/mixin';

import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  name: "Report",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
    LIcon,
    LPolyline,
  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    data: new Date().toISOString().substr(0, 10),
    dateFormatted: new Date(),
    menuData: false,
    myStyle: {
      weight: 2,
      color: "rgb(142, 140, 140)",
      fillColor: "#5f5f5f",
    },
    recolhido: 0,
    distancia: 0,
    sortBy: "data",
    sortDesc: false,
    historicoHeader: [
      //{ text: "Id", value: "id" },
      { text: "Contentor", value: "contentor", align: 'center' },
      { text: "Sensor", value: "sensor", align: 'center' },
      { text: "Hora", value: "data", align: 'center' },
      { text: "Reports", value: "report", align: 'center' },
      { text: "Rua", value: "rua", align: 'center' },
      { text: "Ultimo Nivel", value: "nivel", align: 'center' },
      { text: "Fotos", value: "fotos", align: 'center' },
      { text: "Mapa ", value: "position", sortable: false, align: 'center' },
    ],
    historico: [],
    registrosHeader: [
      { text: "Km", value: "km", align: 'center' },
      { text: "Tipo", value: "tipo", align: 'center' },
      { text: "Quantidade", value: "quantidade", align: 'center' },
      { text: "Data", value: "data_registro", align: 'center' },
    ],
    hstCircuitosHeader: [
      { text: "Mostrar", value: "id", sortable: true, align: 'center' },
      { text: "Camião", value: "lcamiao", align: 'center' },
      { text: "Rota", value: "lrota", align: 'center' },
      { text: "Descarga (KG)", value: "descarga", align: 'center' },
      { text: "Funcionário", value: "lfuncionario", align: 'center' },
      { text: "Inicio", value: "inicio", align: 'center' },
      { text: "Fim", value: "fim", align: 'center' },
    ],
    report: [],
    rotas: [],
    cartrack: [],
    search: "",
    selecionado: false,
    markers: [
      {
        id: "m1",
        position: { lat: 47.41322, lng: -1.2 },
        tooltip: "tooltip for marker1",
        icon: icon.glyph({
          prefix: "mdi",
          glyph: "trash-can-outline",
        }),
      },
    ],
    caminhoes: [],
    zoom: 11,
    center: latLng(41.460349, -8.3771063, 12),
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
    attribution: "",
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
      //scrollWheelZoom: false,
    },
    showMap: true,
    geojson: null,
    iconSize: 45,
    loaders: {
      report: false,
      historicoReport: true,
    },
    editKMs: false,
    editHST: null,
  }),
  methods: {
    getlatLng: function (lat, lng) {
      return { lat, lng };
    },
    updateZoom: function (e) {
      this.zoom = e;
    },
    centerZoom(position) {
      this.zoom = 17;
      this.zoomUpdate = 17;
      this.center = latLng(0, 0);
      setTimeout(() => {
        this.center = position;
      }, 500);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    exportar() {
      this.$http
        .get("relatorios/rota/" + this.selecionado.id, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.$router.push();

          var link = document.createElement("a");
          link.href = this.API_URL+"/" + response.data;
          link.target = "_blank";
          link.download = "fileName";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {});
    },
    exportarXLS(){
      this.$http
        .get("relatorios/rotaxls/" + this.selecionado.id, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.$router.push();

          var link = document.createElement("a");
          link.href = this.API_URL+"/" + response.data;
          link.target = "_blank";
          link.download = "fileName";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {});
    },
    pesquisarCircuito(item) {
      this.loaders.historicoReport = true;

      this.selecionado = item;
      this.getCarTrack(item);

      this.report = [];

      var IDs = item.reports.map(function(item) {
        return item.contentor;
      });

      this.$http.get("graphql?query={ \
        contentors ( \
          where: { \
            id_in: ["+IDs+"] \
          }) { \
            id, \
            sensor, \
            numero_contentor, \
            rua, \
            freguesia,\
            lat, \
            lng \
        } \
      }",
      {
        headers: {
          Authorization: `Bearer ${this.getObjeto("token")}`,
        },
      })
      .then((response) => {
        var contInfo = response.data.data.contentors;

        item.reports.forEach((i) => {
          var contentor = contInfo.filter(cont => cont.id == i.contentor)[0];

          this.report.push({
            id: i.id,
            sensor: contentor.sensor ? true : false,
            data: i.data,
            contentor: contentor.numero_contentor,
            rua: contentor.rua,
            freguesia: contentor.freguesia,
            recolheuLixo: i.recolheuLixo,
            report: true,
            tampaPartida: i.tampaPartida,
            lixofora: i.lixofora,
            temAvaria: i.temAvaria,
            precisaLimpeza: i.precisaLimpeza,
            temMonstro: i.monstros,
            recolheuMonstro: i.recolheuMostros,
            fezHigenizacao: i.fezHigenizacao,
            fezManutencao: i.fezManutencao,
            nivel: i.nivel,
            fotos: i.fotos,
            position: latLng(contentor.lat, contentor.lng),
            obsAvaria: i.obsAvaria,
            obsLixo: i.obsLixo,
          });
        });
        this.loaders.historicoReport = false;
      })
      .catch((error) => {
          console.log(error);
          this.loaders.historicoReport = false;
      });
    },
    editarCircuito(item) {
      this.editHST = item;
      this.editKMs = true;
    },
    async saveHST(id, kms) {
      try {
        kms = Number(kms);
        await this.$http.put("kms/"+id, {km: kms},{
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        });
      }catch(error) {
        console.log(error);
        return null;
      }
    },
    getHistoricoCircuitos(data) {
      this.loaders.report = true;
      data = new Date(data);
      var dataMenor = data.toISOString().split("T")[0] + "T00:00:00.000Z";
      var dataMaior = data.toISOString().split("T")[0] + "T23:59:59.000Z";
      this.historico = [];
      this.$http.get(
        "historicocircuitos?_sort=id:DESC&created_at_lt=" +
          dataMaior +
          "&created_at_gt=" +
          dataMenor,
        {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        }).then((response) => {
          response.data.forEach(async (hst) => {
            let promises = [];
            hst.lcamiao = hst.caminhao.matricula;
            hst.lfuncionario = hst.user.nome;
            hst.ldata = new Date(hst.updated_at).toLocaleString();
            hst.descarga = 0;
            var diffStart, diffEnd;
            hst.kms.forEach((km) => {
              var data = new Date(km.data_registro);
              if (km.tipo == "inicio") {
                hst.inicio = this.dateToHHMM(data);
                diffStart = data;
                hst.kmStart = km.km;
              } else if (km.tipo == "final") {
                hst.fim = this.dateToHHMM(data);
                diffEnd = data;
                hst.kmEnd = km.km;
              } else if (km.tipo == "descarga") {
                hst.descarga += km.quantidade;
              }
            });
            hst.duration = diffEnd ? (diffEnd - diffStart) : "";
            if (hst.rota) {
              hst.lrota = hst.rota.nome;
            }else if (hst.reports != undefined && hst.reports != null && hst.reports.length > 0 && hst.reports[0].rota != null) {
              await this.$http.get("rotas?id=" + hst.reports[0].rota, {
                  headers: {
                    Authorization: `Bearer ${this.getObjeto("token")}`,
                  },
                }).then((responseRotas) => {
                  var rota = responseRotas.data[0];
                  hst.lrota = rota ? rota.nome : "ND";
                }).catch((error) => {
                  console.log(error);
                });
            } else {
              hst.lrota = "Outra";
            }
            this.historico.push(hst);
          });
          this.loaders.report = false;
        }).catch((error) => {
          console.log(error);
          this.loaders.report = false;
        });
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    convertData(data) {
      return new Date(data).toLocaleString();
    },
    getCarTrack(item) {
      var inicio = "";
      var fim = "";
      item.kms.forEach((km) => {
        if (km.tipo == "inicio") {
          inicio = km.data_registro.substring(0, km.data_registro.length - 5);
        } else if (km.tipo == "final") {
          fim = km.data_registro.substring(0, km.data_registro.length - 5);
        }
      });
      if (!fim){
        var now = new Date().toISOString();
        fim = now.substring(0, now.length - 5);
      }
      this.$http
        .get(
          "arcgis/camioesdatahora/" +
            item.caminhao.id_cartrack +
            "/" +
            inicio +
            "/" +
            fim,
          {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          }
        )
        .then((response) => {
          this.cartrack = response.data;
        })
        .catch(function () {
          // handle error

          this.cartrack = undefined;
          this.centerZoom(this.center);
        });
    },
  },
  mounted() {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 500);

    const map = this.$refs.mymap.mapObject;
    map.addControl(new L.Control.Fullscreen());
  },
  watch: {
    data: function (data) {
      this.getHistoricoCircuitos(data);
    },
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    dynamicSizeCamiao() {
      return [this.iconSizeCamiao, this.iconSizeCamiao * 1.15];
    },
    dynamicAnchorCamiao() {
      return [this.iconSizeCamiao / 2, this.iconSizeCamiao * 1.15];
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    enchimentoMedio() {
      let result = "";
      try {
        result = (selecionado.reports.reduce((acc, x) => acc + x.nivel, 0) / selecionado.reports.length).toFixed(2);
      }catch(error) {
        result = "0";
      }
      return result;
    }
  },

  created() {
    this.getHistoricoCircuitos(new Date());

    this.$http
      .get(this.API_URL+"/guimaraes.geojson")
      .then((response) => {
        this.geojson = response.data;
      });
  },
};
</script>

<style>
.v-dialog__content--active {
  z-index: 999 !important;
}
</style>